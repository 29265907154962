<template>
  <div class="login">
    <div class="login-form">
      <div class="login-title">
        <div class="title1">Hi～欢迎登录</div>
        <div class="title2">索道财务系统</div>
      </div>
      <input v-model="phone" type="text" placeholder="请输入账号/手机号" />
      <input v-model="password" type="password" placeholder="请输入密码" />
      <!-- <van-field v-model="phone" type="tel"/>
      <van-field v-model="password" type="password" /> -->
      <van-button
        color="#586CB1"
        round
        @click="onConfirm"
        :disabled="disabledLogin"
        block
        >立即登录</van-button
      >
      <van-button
        round
        color="#28C445"
        @click="wechat_login"
        icon="wechat"
        block
        >绑定账号登录</van-button
      >
    </div>
  </div>
</template>

<script>
import { login, login_wechat } from "@/api/base";
import { Toast } from "vant";

export default {
  name: "Login",
  data() {
    return {
      phone: "",
      password: "",
    };
  },
  computed: {
    disabledLogin() {
      return !this.phone || !this.password;
    },
  },
  watch: {},
  methods: {
    onConfirm() {
      login({
        phone: this.phone,
        password: this.password,
      }).then((response) => {
        if (response.data.code === 200) {
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem('name',response.data.data.info.name);
          localStorage.setItem('phone',response.data.data.info.phone);
          localStorage.setItem("permission", response.data.data.info.permission);
          
          this.$router.push("/finance_report");
        } else {
          Toast.fail(response.data.msg);
        }
      });
    },
    wechat_login() {
      // login_wechat({
      //   openid: localStorage.getItem("openid"),
      // }).then((response) => {
      //   if (response.data.code === 200) {
      //     localStorage.setItem("token", response.data.data.token);
      //     localStorage.setItem('name',response.data.data.info.name)
      //     localStorage.setItem('phone',response.data.data.info.phone)
      //     localStorage.setItem("permission", response.data.data.info.permission);
      //     this.$router.push("/finance_report");
      //   } else {
      //     Toast.fail(response.data.msg);
      //   }
      // });
    },
  },
  created() {
    // if (!this.$route.query.openid) {
    //   window.location.href = 'http://ynd-admin.hnjyb.cn/api/getAppid';
    // } else {
    //   localStorage.setItem('openid', this.$route.query.openid)
    // }
  }
};
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .login-title {
    margin-bottom: 100px;
    width: 290px;
    text-align: left;
  }

  .login-form {
    width: 290px;
    margin-bottom: 200px;

    input {
      width: 100%;
      text-align: center;
      box-sizing: border-box;
      height: 40px;
      border: 1px solid #586cb1;
      border-radius: 20px;
      margin-bottom: 15px;
    }

    .van-button {
      margin-top: 15px;
    }
  }
}

.login .title1 {
  font-size: 16px;
  font-weight: 500;
  color: #586cb1;
  text-align: left;
}

.login .title2 {
  margin-top: 2px;
  font-size: 22px;
  font-weight: bold;
  color: #586cb1;
  text-align: left;
}
</style>