<template>
  <!-- 报表统计-->
  <div class="page-back">
    <y-header>
    </y-header>
    <div class="write-off-time">
      <span>选择核销时间：</span>
      <div class="operate">
        <a-button class="search-button" type="primary" @click="onConfirmResetSmall">清空</a-button>
        <a-button class="search-button" type="primary" @click="getBillData">搜索</a-button>
      </div>
    </div>
    <div class="write-off-time">
      <a-date-picker class="flex-item-extend" v-model="startDate" :disabled-date="disabledStartDate" :inputReadOnly="inputReadOnly" :allowClear="allowClear" :locale="locale" placeholder="开始时间" @change="onStartChange"></a-date-picker>
      <span class="space">~</span>
      <a-date-picker class="flex-item-extend" v-model="endDate" :disabled-date="disabledEndDate" :inputReadOnly="inputReadOnly" :allowClear="allowClear" :locale="locale" placeholder="结束时间"  @change="onEndChange"></a-date-picker>
    </div>
    <div v-if="!income && (permission !== 1 || (permission === 1 && !writeoff)) && !loading" class="no-data">暂无数据</div>
    <div id="income-expend" :class="['income-expend', !income ? 'mar-left' : '']"></div>
    <div id="write-off" :class="['write-off', !writeoff || permission !== 1 ? 'mar-left' : '']"></div>
    <div class="div-view"></div>
  </div>
</template>

<script>
import { getIncomeExpend, getWriteOff } from "@/api/base";
import YHeader from "@/components/YHeader.vue";
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent
} from 'echarts/components';
import { BarChart, PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { LabelLayout } from 'echarts/features';
import { DatePicker, Button, message } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
import Vue, { nextTick } from "vue";

moment.locale("zh-cn");
Vue.use(DatePicker);
Vue.use(Button);

export default {
  name: "FinanceReport",
  data() {
    return {
      startTime: "",
      endTime: "",
      startDate: null,
      endDate: null,
      incomeChart: null,
      income: false,
      writeoffChart: null,
      writeoff: true,
      loading: false,
      locale,
      allowClear: false,
      inputReadOnly: true, //禁止弹出虚拟键盘
      permission: 0,
      colors: ["#f1c336", "#548cf2", "#8c78e7", "#58deb4"],
    };
  },
  mounted() {
    this.permission = localStorage.getItem("permission");
    
    this.getBillData();
    this.$nextTick(()=>{
      this.initECharts();
    })
  },
  methods: {
    disabledStartDate(current) {
      if (this.endTime) {
        return current && (current > moment().endOf('day') || current > moment(this.endTime).endOf("day"));
      }
      return current && current > moment().endOf('day');
    },
    disabledEndDate(current) {
      if (this.startTime) {
        return current && (current < moment(this.startTime).startOf("day") || current > moment().endOf('day'));
      }
      return current && current > moment().endOf('day');
    },
    // 开始时间
    onStartChange(date, dateString) {
      this.startTime = dateString;
    },
    // 结束时间
    onEndChange(date, dateString) {
      this.endTime = dateString;
    },
    onConfirmResetSmall() {
      this.startTime = ""
      this.endTime = ""
      this.startDate = null;
      this.endDate = null;
      this.writeoff = false;
      this.income = false;
      this.getBillData();

    },
    getBillData(){
      let data = {}
      if ((this.startTime && !this.endTime) || (!this.startTime && this.endTime)) {
        message.error('开始时间，截止时间必须同时选择');
        return;
      }
      //时间
      if (this.startTime) {
        data.start_time = this.startTime
        data.end_time = this.endTime
      }
      message.config({
        top: "180px",
      })
      const hide = message.loading("加载中...", 0);
      this.loading = true;
      var count = 0;
      const maxCount = this.permission == 1 ? 2 : 1;

      getIncomeExpend(data).then( response => {
        if (JSON.stringify(response.data.data) !== "[]") {
          this.income = true;
          this.$nextTick(()=>{
            this.setIncomeExpendOptions(response.data);
          })
        } else {
          this.income = false;
        }
        count ++;
        if (count >= maxCount) {
          hide();
          this.loading = false;
        }
      });
      if (this.permission == 1) {
          getWriteOff(data).then( response => {
          if (JSON.stringify(response.data.data) !== "[]" && JSON.stringify(response.data.data.key) !== "[]" && JSON.stringify(response.data.data.data) !== "[]") {
            this.writeoff = true;
            this.$nextTick(()=>{
              this.setWriteOffOptions(response.data);
            })
          } else {
            this.writeoff = false;
          }

          count++;
          if (count >= maxCount) {
            hide();
            this.loading = false;
          }
        })
      }
    },
    initECharts() {
      echarts.use([
        TooltipComponent,
        GridComponent,
        LegendComponent,
        BarChart,
        PieChart,
        CanvasRenderer,
        LabelLayout,
        DataZoomComponent
      ]);
    },
    setIncomeExpendOptions(data) {
      if (!this.incomeChart) {
        var incomeChartDom = document.getElementById('income-expend');
        if (incomeChartDom) {
          this.incomeChart = echarts.init(incomeChartDom);
        }
      }
      var option = {
        tooltip: {
          trigger: 'item',
          textStyle: {
            fontSize: 40,
          },
          confine: true,
          formatter: params => {
            if (params) {
              return "<div style='display: flex; flex-direction: row; align-items: center;font-size: 40px;'>" + params.marker + params.name + "：" + params.value +"<div>"
            }
          }
        },
        legend: {
          left: 'center',
          itemGap: 40,
          itemWidth: 50,
          itemHeight: 30,
          textStyle: {
            fontSize: 40,
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            data: data.data,
            itemStyle: {
              color: v => {
                return v.dataIndex>3 ? "#586cb1" : this.colors[v.dataIndex]
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              fontSize: 40,
              formatter: "{b}:\n{c}"
              
            },
            labelLine: {
              length: 10,
              length: 20,
            }
          }
        ]
      };
      option && this.incomeChart.setOption(option);
      
    },
    setWriteOffOptions(data) {
      if (data.data.data && data.data.data.length && data.data.name && data.data.name.length && data.data.key && data.data.key.length )
      {
        if (!this.writeoffChart) {
          var writeoffChart = document.getElementById('write-off');
          if (writeoffChart) {
            this.writeoffChart = echarts.init(writeoffChart);
          }
        }
        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            },
            textStyle: {
              fontSize: 40,
            },
            padding: [10, 10, 10, 10],
            confine: true,
            formatter: params => {
              console.log(params);
              
              if (params && params.length) {
                var tip = "";
                tip += "<div style='display: flex; flex-direction: column; align-items: start;font-size: 40px;'>" + params[0].name + "<br />";
                for (var i=0; i<params.length; i++) {
                  tip += "<div>"+params[i].marker + params[i].seriesName + "：" + params[i].value +"</div>"
                }
                tip += "</div>"
                return tip
              } else if (params) {
                return "<div style='display: flex; flex-direction: row; align-items: center;font-size: 40px;'>" + params.marker + params.name + "：" + params.value +"<div>"
              }
            }
          },
          legend: {
            itemGap: 40,
            itemWidth: 50,
            itemHeight: 30,
            textStyle: {
              fontSize: 40,
            },
          },
          grid: {
            left: '10%',
            right: '10%',
            bottom: '10%',
            top: '10%',
            containLabel: true
          },
          yAxis: {
            name: "核销数",
            type: 'value',
            nameTextStyle: {
              fontSize: 40,
            },
            axisLabel: {
              fontSize: 40,
            },
            show: true,
            nameGap: 40,
            axisLine: {
              show: true,
              symbol: ["none", "arrow"],
            }
          },
          xAxis: {
            type: 'category',
            data: data.data.key,
            name: "日期",
            nameTextStyle: {
              fontSize: 40,
            },
            axisLabel: {
              fontSize: 40,
              rotate: 45,
              shadowOffsetY: 10,
              interval: 0,
            },
            axisLine: {
              show: true,
              symbol: ["none", "arrow"],
            }
          },
          series:  data.data.data.map((v, index)=>{
              return {
                name: data.data.name[index],
                type: 'bar',
                stack: 'total',
                itemStyle: {
                  color: index>3 ? "#586cb1" : this.colors[index],
                },
                label: {
                  show: true,
                  fontSize: 30,
                  formatter: params => {
                    if (Number(params.value) > 100000) {
                      return `${(Number(params.value)/10000).toFixed(0)}万`
                    }
                    if (Number(params.value) > 10000) {
                      return `${(Number(params.value)/10000).toFixed(1)}万`
                    }
                    return `${Number(params.value).toFixed(0)}`
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: v
              }
          }),
          dataZoom: [
            {
              type: "slider", //给x轴设置滚动条
              show: data.data.key.length>=7, //flase直接隐藏图形
              xAxisIndex: [0],
              bottom: 0,
              height: 40,
              showDetail: false,
              startValue: 0, //滚动条的起始位置
              endValue: data.data.key.length, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
            },
          ],
        };
        option && this.writeoffChart.setOption(option);
      }
      
    },
    dateFormat(date){
      return date.substr(0,10)
    }
  },
  components: {YHeader}
}
</script>

<style scoped lang="scss">
  .page-back {
    overflow: hidden;
  }
  .income-expend, .write-off
  {
    width: 100%;
    height: 300px;
    margin-top: 30px;
    background-color: #fff;
  }
  .write-off
  {
    height: 400px;
  }
  .div-view {
    width: 100%;
    height: 20px;
  }

  .write-off-time {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 5px 20px;

    span {
      white-space: nowrap;
    }

    .space {
      margin-left: 5px;
      margin-right: 5px;
    }
    
    
    .flex-item-extend {
      flex: 1;
    }

    .operate {
      display: flex;
      flex-direction: row;
      margin-left: 5px;
      .search-button {
        background: #586cb1;
        border-color: #586cb1;
      }
      &>.search-button:not(:last-child) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  .no-data {
    margin-top: 30px;
  }
  .mar-left {
    margin-left: 100vw;
  }
</style>